<template>
    <section id="paiement">
        <div class="col-xs-12">
            <div class="samir-step-wizard">
                <div class="ssw__item" :class="{ active: currentStep === 'step1' }">
                    <div>
                        <h4 class="ssw__numero">1</h4>
                        <p class="ssw__description">Email</p>
                    </div>
                </div>
                <div class="ssw__item" :class="{ active: currentStep === 'step2' }">
                    <div>
                        <h4 class="ssw__numero">2</h4>
                        <p class="ssw__description">Paiement</p>
                    </div>
                </div>
                <div class="ssw__item" :class="{ active: currentStep === 'step3' }">
                    <div>
                        <h4 class="ssw__numero">3</h4>
                        <p class="ssw__description">Téléchargement</p>
                    </div>
                </div>
            </div>
            <div class="p-3" style="min-height: 400px">
                <component :is="currentStep" @suivant="suivant" :client="client" :commande="commande"></component>
            </div>
        </div>
    </section>
</template>

<script>
import Step1 from "./includes/Step1";
import Step2 from "./includes/Step2";
import Step3 from "./includes/Step3";

export default {
    components: {
        Step1, Step2, Step3
    },
    data() {
        return {
            currentStep: 'step1',
            client: {},
            commande: {},
        }
    },
    mounted() {
        //
    },
    methods: {
        suivant(data) {
            if(data.from === 'step1') {
                this.client.methode = data.methode;
                this.client.email = data.email;
                this.currentStep = 'step2';
            }

            if(data.from === 'step2') {
                this.client.password = data.response.password;
                this.commande = data.response.commande;
                this.currentStep = 'step3';
            }
        }
    }
};
</script>
