<template>
    <div class="p-3" style="position: relative">
        <loader-overlay v-if="loading"></loader-overlay>
        <template v-if="client.methode === 'paypal'">
            <div id="paypal-button-container"></div>
        </template>
        <template v-else>
            <!-- stripe : Display a payment form -->
            <form id="payment-form" @submit.stop.prevent="payer">
                <div id="payment-element" ref="PmtElement">
                    <!--Stripe.js injects the Payment Element-->
                </div>
                <button id="submit" v-if="!processing"
                        class="mt-3 mb-2 rounded-xl text-center btn btn-full w-100 btn-m bg-blue-dark btn-icon text-uppercase font-700">
                    <i class="fi fi-br-credit-card rounded-xl font-16 text-center"></i> Payer
                </button>
                <div v-if="message.length"
                     id="payment-message"
                     class="text-center alert alert-warning">{{ message }}</div>
            </form>
        </template>
    </div>
</template>

<script>
import {uniqueId} from "lodash";
import uniqid from "uniqid";

export default {
    props: ['client'],
    data() {
        return {
            loading: false,
            processing: false,
            stripe: null,
            elements: null,
            id_commande: null,
            message: "",
        }
    },
    async mounted() {
        if(this.client.methode === 'stripe') {
            // This is your test publishable API key.
            this.stripe = Stripe(STRIPE_PUBLISHABLE_KEY, { locale: 'fr' });
            await this.initializeStripe();
        }

        if(this.client.methode === 'paypal') {
            await this.initializePaypal()
        }
    },
    methods: {
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        async initializePaypal() {
            const commande = {
                id_commande: uniqid(),
                client: this.client,
            }
            const that = this;

            paypal.Buttons({
                style: {
                    shape: 'rect',
                    color: 'gold',
                    layout: 'vertical',
                    label: 'pay',
                },
                enableStandardCardFields: false,
                createOrder: function(data, actions) {
                    // This function sets up the details of the transaction, including the amount and line item details.
                    return actions.order.create({
                        purchase_units: [{
                            "amount": {
                                "currency_code": "EUR",
                                "value": prix,
                                "breakdown": {
                                    "item_total": {
                                        "currency_code": "EUR",
                                        "value": prix
                                    },
                                    "tax_total": {
                                        "currency_code": "EUR",
                                        "value": "0"
                                    }
                                }
                            },
                            "items": [
                                {
                                    "name": "Mon cahier de lecture",
                                    "description": "Kit de première lecture",
                                    "unit_amount": {
                                        "currency_code": "EUR",
                                        "value": prix
                                    },
                                    "tax": {
                                        "currency_code": "EUR",
                                        "value": "0"
                                    },
                                    "quantity": "1",
                                }
                            ],
                        }]
                    });

                },
                onClick: function (e) {
                    console.log('on click', e);
                },
                onCancel: function () {
                    //$('.loading-screen').fadeOut();
                    console.log('on cancel');
                },
                onError: function (err) {
                    console.log('on error', err);

                },
                onApprove: async function(data, actions) {
                    console.log('on approve');

                    commande.status_paiement = "succeeded paypal";

                    const res = await actions.order.capture(); // This function captures the funds from the transaction.
                    const response = (await axios.post('/api/commandes', commande)).data;

                    // go to step 3
                    that.$emit('suivant', {
                        from: 'step2',
                        response
                    });

                    return res;
                }
            }).render('#paypal-button-container');
        },
        // Fetches a payment intent and captures the client secret
        async initializeStripe() {
            // The items the customer wants to buy
            const data = (await axios.post("/api/stripe/create")).data;
            this.elements = this.stripe.elements({clientSecret: data.clientSecret});
            this.id_commande = data.id_commande;

            const paymentElement = this.elements.create("payment", { layout: "tabs" });
            paymentElement.mount("#payment-element");
            this.loading = false;
        },
        async payer() {
            this.processing = true;
            this.loading = true;

            const {error, paymentIntent} = await this.stripe.confirmPayment({
                elements: this.elements,
                redirect: 'if_required', // on préfère ne pas rediriger automatiquement pour pouvoir intercepter ici le résultat du paiement
            });

            // This point will only be reached if there is an immediate error when
            if(error) {
                if (error.type === "card_error" || error.type === "validation_error") {
                    this.message = error.message;
                } else {
                    this.message = "Une erreur est survenue.";
                }
            } else {
                switch (paymentIntent.status) {
                    case "succeeded":
                        this.message = "✅ Payment effectué !";
                        // 1. Sauvegarde commande
                        const commande = {
                            id_commande: this.id_commande,
                            client: this.client,
                            status_paiement: 'succeeded stripe',
                        }

                        const response = (await axios.post('/api/commandes', commande)).data;

                        // go to step 3
                        this.$emit('suivant', {
                            from: 'step2',
                            response
                        });

                        break;
                    case "processing":
                        this.message = "Paiement en cours ...";
                        break;
                    case "requires_payment_method":
                        this.message = "Votre paiement a échoué, veuillez réésayer";
                        break;
                    default:
                        this.message = "Une erreur est survenue.";
                        break;
                }
            }
            this.loading = false;
            this.processing = false;
        },
    }
};
</script>

<style>
.bg-blue-dark {
    background-color: #4dc0b5;
    color: #fff;
}
</style>
