import Vue from 'vue'
// Form Validation - Vee Validate
import {ValidationProvider, ValidationObserver, setInteractionMode, extend, localize} from "vee-validate";
import * as rules from 'vee-validate/dist/rules';
import fr from 'vee-validate/dist/locale/fr.json';
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
}); // importe toute les rules
localize('fr', fr);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
// Comment est faite la validation (agressive, passive, lazy, eager)
setInteractionMode('eager'); // https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html#interaction-modes
