require('./bootstrap');
require('./splide')
require('./stripe')
require('./vee-validate')

import store from "./store.js";
import Vue from 'vue'
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
Vue.use(LottieVuePlayer);

// Components
import Paiement from './components/Paiement'
import LoaderOverlay from './includes/LoaderOverlay'
Vue.component('loader-overlay', LoaderOverlay)

const app = new Vue({
    store,
    el: '#app',
    components: {
        Paiement
    },
    data: function () {
        return {
            // ...
        }
    },
    computed: {
        //
    },
    // https://github.com/hilongjw/vue-progressbar#demo
    mounted() {
        // ...
        console.log('mounted')
    },
    async created() {
        // ...
    }
});
