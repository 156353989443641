<template>
    <!-- Adresse email -->
    <validation-observer ref="emailForm" v-slot="{ handleSubmit, invalid }">
        <form @submit.stop.prevent="" novalidate>
            <validation-provider name="email" :rules="{ required: true, email: true }" v-slot="v">
                <div class="form-group input-style" :class="getValidationState(v) ? 'valid-form-group' : 'invalid-form-group'">
                    <label for="email" class="color-blue-dark">Email</label>
                    <input
                        id="email" type="email"
                        v-model="email"
                        autocomplete="email"
                        placeholder="Email" class="form-control">
                    <div class="invalid-feedback d-block">{{ v.errors[0] }}</div>
                </div>
            </validation-provider>

            <div class="d-flex justify-content-between">
                <button class="btn btn-outline-info mr-1 flex-grow-1" @click="payer('stripe')">Payer par carte</button>
                <button class="btn btn-outline-info ml-1 flex-grow-1" @click="payer('paypal')">Payer avec Paypal</button>
            </div>
        </form>
    </validation-observer>
</template>


<script>
export default {
    data() {
        return {
            email: ''
        }
    },
    mounted() {
        //
    },
    methods: {
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        async payer(methode) {
            const success = await this.$refs.emailForm.validate();
            if (!success) { return; }

            // go to step 2
            this.$emit('suivant', {
                from: 'step1',
                email: this.email,
                methode
            })
        },
    }
};
</script>
