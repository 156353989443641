<template>
    <div class="p-3 text-center">
        <lottie-vue-player src="https://assets9.lottiefiles.com/packages/lf20_y2hxPc.json"
                           backgroundColor="#f5f5f7"
                           :player-controls="false"
                           :loop="true" :autoplay="true"
                           class="text-center"
                           style="width: 120px; height: 120px; margin: 0 auto">
        </lottie-vue-player>
        <h5>Merci pour votre achat !</h5>

        <div class="card my-4">
            <div class="card-body">
                <p class="text-primary">Commande n°{{ commande.id_commande }}</p>
                Un email vous a été envoyé avec vos informations de connection ainsi qu'un lien pour télécharger votre kit en PDF. <br>
                Si vous ne le trouvez pas pensez à regarder dans vos spams. <br>
                Il reste disponible au téléchargement à tout instant depuis votre espace client. <br>

                <h6 class="mt-4">Vos informations de connection <span class="text-danger">(à conserver)</span>:</h6>
                <div class="my-3">
                    <b>Login: <span class="text-primary">{{ client.email }}</span></b> <br>
                    <b>Mot de passe: <span class="text-primary">{{ client.password }}</span></b>

                    <div class="mt-3"><a href="/login" class="btn btn-primary">Connexion</a></div>
                </div>
            </div>
        </div>
        <a :href="commande.url" class="btn btn-success btn-block btn-lg">
            <i class="fi fi-rr-file-pdf"></i> Télécharger mon kit en PDF
        </a>
    </div>
</template>

<script>
export default {
    props: ['client', 'commande'],
};
</script>
