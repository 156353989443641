import '@splidejs/splide/css/sea-green';
import Splide from '@splidejs/splide';

document.addEventListener( 'DOMContentLoaded', function() {
    const splide = new Splide('.splide', {
        perPage: 1,
        type   : 'loop',
        autoplay: true,
        interval: 6000,
        width: "100%",
        // Responsive
        breakpoints: {
            640: {
                arrows: false,
            },
        }
    });
    splide.mount();
} );
